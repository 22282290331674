import React from "react"
import PropTypes from "prop-types"

class RedditAccessToken extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 style={{width: '100%'}}>Thanks for authorizing!</h1>
          <p>Give us a few seconds to connect with your account...</p>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}

export default RedditAccessToken
