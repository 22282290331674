import React from "react"
import PropTypes from "prop-types"

class RedditAuthorization extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 style={{width: '100%'}}>Thanks for signing up!</h1>
          <p>Please click the link below to authorize us to access your saved posts.</p>
          <a className="btn btn-blue" href={this.props.url}>Authorize</a>
        </div>
      </div>
    );
  }
}

export default RedditAuthorization
