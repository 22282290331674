import React from "react"
import PropTypes from "prop-types"

class UpgradePanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="pricing-section">
        <h2>Not getting enough with free?</h2>
        {/* <h3>500 items max with searching</h3> */}
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="carousel-caption">
                <div className="card text-center border-0">
                  <div className="card-header bg-transparent justify-content-center">
                    <h3>Buy Premium</h3>
                  </div>
                  <div className="card-body ">
                    <ul className="list-unstyled">
                      <li>
                        <span>Pay just $2/month</span> for unlimited items and filtering/tagging in addition to searching.
                      </li>
                    </ul>
                    <div className="card-header bg-transparent justify-content-center">
                      <h3>Features</h3>
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="d-flex align-items-center justify-content-center">
                        Unlimited Items
                      </li>
                      { /* <li className="d-flex align-items-center justify-content-center">
                        Searching
                      </li> */}
                      <li className="d-flex align-items-center justify-content-center">
                        Filtering
                      </li>
                      <li className="d-flex align-items-center justify-content-center">
                        Tagging
                      </li>
                    </ul>
                  </div>     
                </div>           
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption">
                <div className="card text-center border-0">
                  <div className="card-header bg-transparent justify-content-center">
                    <h3>Subreddits</h3>
                  </div>
                  <div className="card-body ">
                    <ul className="list-unstyled">
                      <li>
                        <span>Pay just $2/month</span> for unlimited items and filtering/tagging in addition to searching.
                      </li>
                    </ul>
                    <div className="subreddits">
                      <ul className="list-group">
                        <li className="list-group-item" aria-disabled="true"><a href="#">cringepics <span>2</span></a></li>
                        <li className="list-group-item"><a href="#">autoshow<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">startup_ideas<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">covid19<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">cookingshow<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">bestcars<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">programming<span>2</span></a></li>
                        <li className="list-group-item"><a href="#">webdesign<span>2</span></a></li>
                      </ul>
                    </div>
                  </div>     
                </div>   
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption">
                <div className="card text-center border-0">
                  <div className="card-header bg-transparent justify-content-center">
                    <h3>Tagging</h3>
                  </div>
                  <div className="card-body ">
                    <ul className="list-unstyled">
                      <li>
                        <span>Pay just $2/month</span> for unlimited items and filtering/tagging in addition to searching.
                      </li>
                    </ul>
                    <ul className="most-recent">
                      <li><a href="#">recent new cars</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">alibaba</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">amazon prime</a></li>
                      <li><a href="#">recent new cars</a></li>
                      <li><a href="#">recent new cars</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">alibaba</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">amazon prime</a></li>
                      <li><a href="#">recent new cars</a></li>
                      <li><a href="#">recent new cars</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">alibaba</a></li>
                      <li><a href="#">adobe</a></li>
                      <li><a href="#">amazon prime</a></li>
                      <li><a href="#">recent new cars</a></li>
                    </ul>
                  </div>     
                </div>   
              </div>
            </div>
          </div>
          <div className="upgrade text-right"><button className="btn btn-blue upgrade-button">Upgrade</button></div>
        </div>
      </div>
    );
  }
}

export default UpgradePanel
