import React from "react"
import PropTypes from "prop-types"

import RedditAuthorization from "./RedditAuthorization"
import RedditAccessToken from "./RedditAccessToken"
import NeedInitialSync from "./NeedInitialSync"
import SyncedDashboard from "./SyncedDashboard"

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      itemsSynced: null,
      syncStatus: null,
      userFetchIntervalId: null,
      userStatus: null,
      userIsPremium: false
    }
  }

  componentDidMount() {
    this.fetchUserStatus();
  }

  componentWillUnmount() {
    this.cancelUserStatusInterval();
  }

  cancelUserStatusInterval() {
    if (this.state.userFetchIntervalId != null) {
      clearInterval(this.state.userFetchIntervalId);
    }
  }

  fetchUserStatus() {
    fetch('/api/v1/users/' + this.props.userId)
      .then(response => response.json())
      .then(data => {
        if (data.error == null) {
          let intervalId = null;

          if (data.status === "synced" || data.status === "needs_reddit_authorization" || data.status == "needs_initial_sync") {
            this.cancelUserStatusInterval();
          } else {
            if (this.state.userFetchIntervalId == null) {
              intervalId = setInterval(this.fetchUserStatus.bind(this), 5000);
            } else {
              intervalId = this.state.userFetchIntervalId;
            }
          }

          this.setState({
            error: false,
            errorMessage: null,
            itemsSynced: data.itemsSynced,
            syncStatus: data.syncStatus,
            userFetchIntervalId: intervalId,
            userStatus: data.status,
            userIsPremium: data.userIsPremium
          })
        } else {
          this.setState({
            error: true,
            errorMessage: data.error
          })
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  performInitialSync() {
    fetch('/api/v1/items/sync')
      .then(response => response.json())
      .then(data => {
        if (data.error == null) {
          let intervalId = null;

          if (data.status === "synced" || data.status === "needs_reddit_authorization" || data.status == "needs_initial_sync") {
            this.cancelUserStatusInterval();
          } else {
            if (this.state.userFetchIntervalId == null) {
              intervalId = setInterval(this.fetchUserStatus.bind(this), 5000);
            } else {
              intervalId = this.state.userFetchIntervalId;
            }
          }

          this.setState({
            error: false,
            errorMessage: null,
            userFetchIntervalId: intervalId,
            userStatus: data.status
          })
        } else {
          this.setState({
            error: true,
            errorMessage: data.error
          })
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  render() {
    return (
      <div className="container text-center" style={{marginTop: '50px', marginBottom: '50px', minHeight: '400px'}}>
        { this.state.error === true &&
            <div className="alert alert-danger">
              { this.state.errorMessage }
            </div>
        }

        { this.state.userStatus === "needs_reddit_authorization" && <RedditAuthorization url={this.props.redditAuthorizationUrl} /> }
        { (this.state.userStatus === "retrieving_access_token" || this.state.userStatus === "retrieving_reddit_username") && <RedditAccessToken /> }
        { (this.state.userStatus === "needs_initial_sync" || this.state.userStatus === "performing_initial_sync") && <NeedInitialSync performInitialSync={this.performInitialSync.bind(this)} userStatus={this.state.userStatus} syncStatus={this.state.syncStatus} itemsSynced={this.state.itemsSynced} /> }
        { (this.state.userStatus === "synced" || this.state.userStatus === "syncing" || this.state.userStatus == "performing_full_sync") && <SyncedDashboard userId={this.props.userId} userIsPremium={this.state.userIsPremium} userStatus={this.state.userStatus} itemsSynced={this.state.itemsSynced} syncStatus={this.state.syncStatus} />}
      </div>
    );
  }
}

export default Dashboard
