import React from "react"
import PropTypes from "prop-types"

import ItemsTrackedBanner from "./ItemsTrackedBanner"
import SavedItemsList from "./SavedItemsList"
import TagsList from "./TagsList"
import UpgradePanel from "./UpgradePanel"
import SubredditList from "./SubredditList"

class SyncedDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshItems: null, // This is to temporarily send in updates to the item list.. needs refactored.
      refreshSubreddits: null, // This is to temporarily send in updates to the subreddit list.. needs refactored.
      subredditFilters: [],
      tagFilters: []
    }
  }

  refreshItemList() {
    this.setState({
      refreshItems: Math.random()
    })
  }

  toggleTagFilter(tagName) {
    event.preventDefault();

    let tagFilters = this.state.tagFilters;

    if (this.state.tagFilters.indexOf(tagName) === -1) {
      tagFilters.push(tagName);

      this.setState({
        tagFilters: tagFilters,
        refreshItems: Math.random()
      })
    } else {
      tagFilters = tagFilters.filter(e => e !== tagName);

      this.setState({
        tagFilters: tagFilters,
        refreshItems: Math.random()
      })
    }
  }

  toggleSubredditFilter(subredditName) {
    event.preventDefault();

    let subredditFilters = this.state.subredditFilters;

    if (this.state.subredditFilters.indexOf(subredditName) === -1) {
      subredditFilters.push(subredditName);

      this.setState({
        subredditFilters: subredditFilters,
        refreshItems: Math.random()
      })
    } else {
      subredditFilters = subredditFilters.filter(e => e !== subredditName);

      this.setState({
        subredditFilters: subredditFilters,
        refreshItems: Math.random()
      })
    }
  }

  refreshSubredditList() {
    this.setState({
      refreshSubreddits: Math.random()
    })
  }

  render() {
    return (
      <div className="container">
        { (this.props.userStatus === "syncing" || this.props.userStatus === "performing_full_sync") &&
          <div>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>{this.props.syncStatus} ({this.props.itemsSynced} items)</p>
          </div>
        }

        <ItemsTrackedBanner userId={this.props.userId} userIsPremium={this.props.userIsPremium} itemsSynced={this.props.itemsSynced} refreshItemList={this.refreshItemList.bind(this)} />

        <div className="row">
          <div className="col-md-12 text-md-left text-center">
            <h2 className="sub-heading">Tracked Items</h2>
          </div>
        </div>
        <div className="post-section d-md-flex d-block">
          <div className="col-md-8 col-sm-12 recent-posts">
            {/* <div className="filter d-flex justify-content-md-end justify-content-center">
              <button type="button" className="remove" data-toggle="modal" data-target="#exampleModal3"> <img src="/assets/filter-icon.png" alt="filter" /><span>Filter Subreddits</span></button>
            </div> */}
            <SavedItemsList reRender={this.state.refreshItems} userIsPremium={this.props.userIsPremium} tagFilters={this.state.tagFilters} subredditFilters={this.state.subredditFilters} refreshSubredditList={this.refreshSubredditList.bind(this)} />
          </div>

          <div className="col-md-4  col-sm-12 right-sidebar">
            <TagsList userIsPremium={this.props.userIsPremium} toggleTagFilter={this.toggleTagFilter.bind(this)} tagFilters={this.state.tagFilters} />
            <hr style={{marginTop: '50px', marginBottom: '50px'}} />
            <SubredditList userIsPremium={this.props.userIsPremium} subredditFilters={this.state.subredditFilters} toggleSubredditFilter={this.toggleSubredditFilter.bind(this)} reRender={this.state.refreshSubreddits} />
            <hr style={{marginTop: '50px', marginBottom: '50px'}} />
            { !this.props.userIsPremium && <UpgradePanel /> }
            { this.props.userIsPremium && <p>Thanks for supporting us by being a premium user!</p> }
          </div>
        </div>
      </div>
    );
  }
}

export default SyncedDashboard
