import React from "react"
import PropTypes from "prop-types"

class SubredditList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: null,
      subreddits: []
    }
  }

  componentDidMount() {
    this.fetchSubreddits();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reRender != this.props.reRender) {
      this.fetchSubreddits();
    }
  }

  fetchSubreddits() {
    if (!this.props.userIsPremium) {
      return []
    }

    fetch('/api/v1/items?attributes[]=subreddits')
      .then(response => response.json())
      .then(data => {
        this.setState({
          subreddits: data.subreddits
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  render() {
    return (
      <div className="most-recent">
        <h2 style={{marginTop: '20px'}}>Subreddits</h2>
        <div className="subreddits" style={{paddingLeft: '15%', paddingRight: '15%'}}>
          <ul className="list-group">
            { this.state.subreddits.length === 0 ? (
                this.props.userIsPremium ? (
                  <p>No subreddits to show</p>
                ) : (
                  <p>Upgrade now to filter by subreddit!</p>
                )
              ) : (
                this.state.subreddits.map((subreddit) => {
                  let usedForFilter = this.props.subredditFilters.includes(subreddit.name);

                  return (<li key={subreddit.name} className="list-group-item" style={{background: '#f8f9fb', border: 'none', marginRight: '0px', marginTop: '0px', paddingBottom: '0px'}}><a href="#" onClick={() => this.props.toggleSubredditFilter(subreddit.name)} style={{background: usedForFilter ? '#7151FE' : '#FFF', color: usedForFilter ? '#FFF' : '#7151FE', opacity: usedForFilter ? '1' : '0.5'}}>{subreddit.name}<span style={{color: usedForFilter ? '#7151FE' : '#FFF', background: usedForFilter ? '#FFF' : '#7151FE'}}>{subreddit.count}</span></a></li>)
                })
              )
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default SubredditList
