import React from "react"
import PropTypes from "prop-types"

class NeedInitialSync extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 style={{width: '100%'}}>You're almost done setting up!</h1>
          <p>The last step is to go through your saved history and import everything to SavedforReddit.</p>
          <p>Please DO NOT save/unsave anything during this time. This typically takes around 10 seconds or so, but could take several minutes depending on how many items you have.</p>
          { this.props.userStatus === "needs_initial_sync" &&
              <button className="btn btn-blue" onClick={this.props.performInitialSync}>Import</button>
          }
          { this.props.userStatus === "performing_initial_sync" &&
              <div>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <p>{this.props.syncStatus} ({this.props.itemsSynced} items)</p>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default NeedInitialSync
