import React from "react"
import PropTypes from "prop-types"

class TagsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: null,
      tags: []
    }
  }

  componentDidMount() {
    this.fetchTags();
  }

  fetchTags() {
    if (!this.props.userIsPremium) {
      return []
    }

    fetch('/api/v1/tags')
      .then(response => response.json())
      .then(data => {
        this.setState({
          tags: data.tags
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  createTag() {
    event.preventDefault();

    let tagName = this.refs.tagName.value;

    if (tagName == null || tagName == "") {
      return;
    }

    if (this.state.tags.some(e => e.name === tagName)) {
      return;
    }

    if (this.state.tags.length >= 50) {
      this.setState({
        error: true,
        errorMessage: 'You already have a max of 50 tags.'
      })

      return;
    }

    fetch('/api/v1/tags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ name: tagName})
    })
    .then(response => response.json())
    .then(data => {
      if (data['error']) {
        this.setState({
          error: true,
          errorMessage: data['error']
        })
      } else {
        let tags = this.state.tags;
        tags.push(data['tag'])
        tags.sort((a, b) => (a.name > b.name) - (a.name < b.name));

        this.setState({
          error: false,
          errorMessage: null,
          tags: tags
        })
      }
    })
    .catch((error) => {
      console.log(error)
      this.setState({
        error: true,
        errorMessage: 'An unexpected error occurred.'
      })
    });
  }

  render() {
    return (
      <div className="most-recent">
        { this.state.error &&
            <div className="alert alert-danger">
              { this.state.errorMessage }
            </div>
        }
        <form className=" form-inline mr-auto mw-100 navbar-search" onSubmit={this.createTag.bind(this)}>
          <div className="input-group">
            <input type="text" ref="tagName" className="form-control border-0 small" placeholder="Add a new tag here" aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
        <h2 style={{marginTop: '20px'}}>your tags</h2>
        <ul>
          { this.state.tags.length === 0 ? (
            this.props.userIsPremium ? (
              <p>You don't have any tags yet. Create some now!</p>
            ) : (
              <p>Upgrade now to use tags!</p>
            )) : (
              this.state.tags.map((tag) => {
                let usedForFilter = this.props.tagFilters.includes(tag.name);

                return <li key={tag.id}><a href="#" onClick={() => this.props.toggleTagFilter(tag.name)} style={{background: usedForFilter ? '#7151FE' : '#FFF', color: usedForFilter ? '#FFF' : '#000', opacity: usedForFilter ? '1' : '0.5'}}>{tag.name}</a></li>
              })
            )
          }
        </ul>
      </div>
    );
  }
}

export default TagsList
