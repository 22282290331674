import React from "react"
import PropTypes from "prop-types"

class SavedItemsList extends React.Component {
  constructor(props) {
    super(props);

    this.itemId = null;

    this.state = {
      page: 0,
      hasNextPage: false,
      items: [],
      searchTerm: null,
      tags: [],
      tagFilters: this.props.tagFilters
    }

    this.showItemTagModal = this.showItemTagModal.bind(this);
    this.showUnsaveItemModal = this.showUnsaveItemModal.bind(this);
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let tagFiltersChanged = !this.stringArraysAreEqual(prevProps.tagFilters, this.props.tagFilters);
    let subredditFiltersChanged = !this.stringArraysAreEqual(prevProps.subredditFilters, this.props.subredditFilters);

    if (prevState.page != this.state.page || prevState.searchTerm != this.state.searchTerm || tagFiltersChanged || subredditFiltersChanged) {
      this.fetchItems();
    }
  }

  stringArraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    array1.sort();
    array2.sort();

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.stringArraysAreEqual(nextProps.tagFilters, this.props.tagFilters) || !this.stringArraysAreEqual(nextProps.subredditFilters, this.props.subredditFilters)) {
      this.setState({
        tagFilters: nextProps.tagFilters,
        subredditFilters: nextProps.subredditFilters
      })
    } else if (nextProps.reRender != this.props.reRender) {
      this.fetchItems();
    }
  }

  fetchItems() {
    let url = '/api/v1/items?page=' + this.state.page;

    if (this.state.searchTerm) {
      url = url + '&search_term=' + this.state.searchTerm;
    }

    if (this.props.tagFilters.length > 0) {
      this.props.tagFilters.map((tagName) => {
        url = url + '&tagFilters[]=' + tagName
      })
    }

    if (this.props.subredditFilters.length > 0) {
      this.props.subredditFilters.map((subredditName) => {
        url = url + '&subredditFilters[]=' + subredditName
      })
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({
          items: data.items,
          hasNextPage: data.hasNextPage
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  nextPage() {
    if (this.state.hasNextPage) {
      this.setState((state, props) => ({
        page: state.page + 1
      }));
    }
  }

  previousPage() {
    if (this.state.page > 0) {
      this.setState((state, props) => ({
        page: state.page - 1
      }));
    }
  }

  search() {
    event.preventDefault();

    let searchTerm = this.refs.searchterm.value

    if (searchTerm != this.state.searchTerm) { // this check is to prevent changing the page number if the search term did not change 
      this.setState({
        searchTerm: searchTerm,
        page: 0
      })
    }
  }

  showItemTagModal(itemId) {
    this.itemId = itemId;

    fetch('/api/v1/tags?item_id=' + itemId)
      .then(response => response.json())
      .then(data => {
        this.setState({
          error: false,
          errorMessage: null,
          tags: data.tags
        })

        $('#item-tag-modal').modal('show');
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  showUnsaveItemModal(itemId) {
    this.itemId = itemId;

    $('#unsave-item-modal').modal('show');
  }

  addTagToItem(tagId) {
    if (this.state.tags.some(e => e.id === tagId && e.usedByItem === true )) {
      return;
    }

    fetch('/api/v1/item_tags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ tag_id: tagId, item_id: this.itemId })
    })
    .then(response => response.json())
    .then(data => {
      let tags = this.state.tags;
      let tag = tags.find(element => element.id === tagId);
      tag.usedByItem = true;

      this.setState({
        error: false,
        errorMessage: null,
        tags: tags
      })
    })
    .catch((error) => {
      this.setState({
        error: true,
        errorMessage: 'An unexpected error occurred.'
      })
    });
  }

  removeTagFromItem(tagId) {
    if (this.state.tags.some(e => e.id === tagId && e.usedByItem === false )) {
      return;
    }

    fetch('/api/v1/item_tags', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ tag_id: tagId, item_id: this.itemId })
    })
    .then(response => response.json())
    .then(data => {
      let tags = this.state.tags;
      let tag = tags.find(element => element.id === tagId);
      tag.usedByItem = false;

      this.setState({
        error: false,
        errorMessage: null,
        tags: tags
      })
    })
    .catch((error) => {
      this.setState({
        error: true,
        errorMessage: 'An unexpected error occurred.'
      })
    });
  }

  unsaveItem(itemId) {
    fetch('/api/v1/items', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ id: itemId })
    })
    .then(response => response.json())
    .then(data => {
      let items = this.state.items
      items = items.filter(e => e.id !== itemId);

      this.setState({
        items: items
      })

      $('#unsave-item-modal').modal('hide');
      this.props.refreshSubredditList();
    })
    .catch((error) => {
      this.setState({
        error: true,
        errorMessage: 'An unexpected error occurred.'
      })
    });
  }

  render() {
    return (
      <div className="posts">
        <form className="mr-auto mw-100 navbar-search" style={{marginBottom: '15px', paddingLeft: '13%'}} onSubmit={this.search.bind(this)}>
          <div className="input-group">
            <input type="text" ref="searchterm" className="form-control border-0 small" placeholder="Search items by title" aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>

        { this.state.items.map((item) => {
            return (
              <div className="recent-post-items align-items-center d-flex flex-wrap" key={item.id}>
                <div className="counting">
                  <span className="up-down">{item.score}</span>
                </div>
                <div className="post-item d-flex align-items-start">
                  { item.thumbnail ? (
                      <img src={item.thumbnail} style={{width: '140px'}}/>
                    ) : (
                      <img src="/assets/default_item_thumbnail.png" style={{width: '140px'}}/>
                    )
                  }
                  
                  <div className="post-content">
                    <div className="heading-post d-flex flex-wrap justify-content-between align-items-start">
                      <a href={item.permalink} target="_blank"><h3 title={item.title}>{item.truncatedTitle}</h3></a>
                    </div>
                    <div className="author-description text-left">submitted {item.redditCreatedAtString} <span className="author-name">{item.author}</span> to r/<span className="cateory">{item.subreddit}</span></div>
                    <div className="post-bottom d-lg-flex d-block justify-content-between">
                      <div className="sharing">
                        <ul>
                          <li>
                            { item.numberOfComments != null && <a href="#">{item.numberOfComments} comments</a> }
                          </li>
                        </ul>
                      </div>
                      <div className="tags">
                        <ul>
                          <li className="remove blue-text">{item.type}</li>
                          <li><button type="button" className="remove" onClick={() => this.showUnsaveItemModal(item.id)}>remove</button></li>
                          { this.props.userIsPremium && <li><button type="button" className="remove" onClick={() => this.showItemTagModal(item.id)}>tags</button></li> }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }

        { this.state.items.length == 0 &&
          <p style={{paddingLeft: '13%'}}>No items found</p>
        }

        <div className="d-flex justify-content-between" style={{paddingLeft: '13%'}}>
          <button className="btn btn-blue" disabled={this.state.page === 0} onClick={this.previousPage.bind(this)}>Previous</button>
          { this.state.items.length > 0 && <p>{ (this.state.page * 30) + 1 } - { (this.state.page * 30) + this.state.items.length }</p> }
          <button className="btn btn-blue" disabled={!this.state.hasNextPage} onClick={this.nextPage.bind(this)}>Next</button>
        </div>

        <div className="modal fade" id="unsave-item-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Are you sure you want to unsave this post?</h5>

              </div>
              <div className="modal-body">
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-secondary btn-primary" onClick={() => this.unsaveItem(this.itemId)}>Confirm</button>
              </div>
            </div>
          </div>
        </div>

        { /* Item Tag Modal */ }
        { this.props.userIsPremium &&
          <div className="modal fade" id="item-tag-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  { this.state.error &&
                      <div className="alert alert-danger">
                        { this.state.errorMessage }
                      </div>
                  }
                  <div className="most-recent">
                    { this.state.tags.length > 0 && <h2 style={{marginBottom: '20px'}}>Click a tag to add or remove it from an item.</h2> }
                    <ul>
                      { this.state.tags.map((tag) => {
                          return (<li key={tag.id}><button className="btn btn-blue" style={{ fontSize: '16px', background: tag.usedByItem ? '#7151FE' : '#FFF', color: tag.usedByItem ? '#FFF' : '#000', opacity: tag.usedByItem ? '1' : '0.5'}} onClick={() => tag.usedByItem ? this.removeTagFromItem(tag.id) : this.addTagToItem(tag.id)}>{tag.name}</button></li>)
                        })
                      }
                      { this.state.tags.length === 0 &&
                        <p>You have no tags. Create some tags first!</p>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SavedItemsList
