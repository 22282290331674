import React from "react"
import PropTypes from "prop-types"

class ItemsTrackedBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsSynced: this.props.itemsSynced,
      numberOfItems: "--",
      syncing: false,
      userFetchIntervalId: null,
    }
  }

  componentDidMount() {
    this.fetchItemCount();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reRender != this.props.reRender) {
      this.fetchItemCount();

      this.setState((prevState, prevProps) => ({
        itemsSynced: prevState.itemsSynced - 1
      }));
    }
  }

  fetchItemCount() {
    fetch('/api/v1/items?attributes[]=count')
      .then(response => response.json())
      .then(data => {
        this.setState({
          numberOfItems: data.count
        })
      });
  }

  fetchUserStatus() {
    fetch('/api/v1/users/' + this.props.userId)
      .then(response => response.json())
      .then(data => {
        if (data['status'] === 'synced') {
          clearInterval(this.state.userFetchIntervalId);
          this.fetchItemCount();
          this.props.refreshItemList();
          this.setState({ syncing: false, intervalId: null, itemsSynced: data['itemsSynced'] })
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  sync() {
    fetch('/api/v1/items/sync')
      .then(response => response.json())
      .then(data => {
        var intervalId = setInterval(this.fetchUserStatus.bind(this), 5000);

        this.setState({
          syncing: true,
          userFetchIntervalId: intervalId
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: 'An unexpected error occurred.'
        })
      });
  }

  render() {
    var showWarning = !this.props.userIsPremium && (this.state.itemsSynced - 300) > 0;

    return (
      <div className="row tracked-items align-items-center">
        <div className="col-md-6 col-sm-6 count-items d-flex align-items-center justify-content-center justify-content-md-start">
          <img src="/assets/bar-icon.png" />
          <h1>{this.state.numberOfItems} Items tracked</h1>
        </div>
        <div className="col-md-6 col-sm-6 sync-items align-items-center flex-wrap d-flex flex-wrap justify-content-center justify-content-md-end">
          { this.state.syncing &&
            <div className="spinner-border text-primary" role="status" style={{ marginRight: '20px' }}>
              <span className="sr-only">Loading...</span>
            </div>
          }
          <div className="sync-button">
            <button className="btn btn-blue" onClick={this.sync.bind(this)} disabled={this.state.syncing}>Sync</button>
            { /* <a href="#" className="btn btn-blue">Sync</a> */ }
          </div>
          { /* <div className="help">
            <a href="#"><i className="fas fa-question"></i></a>
          </div> */ }
        </div>
        { showWarning &&
            <div className="col-md-6 col-sm-6 count-items d-flex align-items-center justify-content-center justify-content-md-start" style={{paddingLeft: '124px'}}>
              <h5 style={{color: '#FF5D5D'}}>Upgrade now to manage your other {this.state.itemsSynced - 300} items!</h5>
            </div>
        }
      </div>
    );
  }
}

export default ItemsTrackedBanner
